import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class Globals {
  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  isBrowser: boolean;
  TEMPLATE_TYPES = {
    SIDEBAR: 'sidebar',
    ARTICLE: 'article',
    TECHNICAL: 'technical',
    LISTING: 'listing',
    SERVICE_DISCOVERY: 'service_discovery',
    LANDING: 'landing',
    REDOC: 'redoc',
    PRODUCT: 'product',
    APIS_PAGE: 'solution-finder',
    HOMEPAGE: 'homepage',
    API_OVERVIEW: 'api-overview',
    DOC_PAGE: 'doc-page',
    SDK_PAGE: 'sdk',
    PUBLIC: 'public',
  };
  DATE_FORMAT = 'D MMM YYYY';
  static emailValidationRegEx = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[.a-zA-Z]{2,7}$';
  CONTACT_SALES_SEC_HEADER_TITLE = 'Need Help?';
  CONTACT_SALES_GENERIC_TITLE = 'Get in touch with Worldpay';
  CONTACT_SALES_GENERIC_SUBHEADING = 'Ready to partner with Us?';
  CONTACT_SALES_GENERIC_DETAILS = 'Connect with our team by completing the form below.';
  CONTACT_SALES_PRODUCT_TITLE = 'Get in touch with us';
  CONTACT_SALES_PRODUCT_SUBHEADING = 'Do you want to learn more about , or just want to share some feedback?';
  CONTACT_SALES_PRODUCT_DETAILS =
    'We’re here to help. Whether you have questions about our APIs, ' +
    'need technical support, or simply want to share your thoughts, our team is ready to assist you. ' +
    ' Tell us a bit about your issue and we’ll get back to you as soon as possible. ' +
    'Your input is valuable to us, and we look forward to hearing from you!';
}
