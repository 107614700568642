import { Component } from '@angular/core';
import { Globals } from 'app/globals';
import { LoaderService } from 'app/services/loader.service';
import { MetaService } from 'app/services/meta.service';

@Component({
  selector: 'app-contact-sales',
  templateUrl: './contact-sales.component.html',
})
export class ContactSalesComponent {
  title = '';
  subheading = '';
  details = '';
  constructor(public loaderService: LoaderService, public globals: Globals, private metaService: MetaService) {}

  ngOnInit() {
    const currentUrl = window.location.href;

    const productFamilyArray = currentUrl.split('/');
    this.title = this.globals.CONTACT_SALES_PRODUCT_TITLE;
    // Capitalize the first letter L for family and family api
    const subheadingArray = this.globals.CONTACT_SALES_PRODUCT_SUBHEADING.split(',');
    const productFamilyApiArray = productFamilyArray[productFamilyArray.length - 2].split('-');
    const capitalizeProductFamilyApiArray = productFamilyApiArray.map(
      (api) => api.charAt(0).toUpperCase() + api.substring(1)
    );
    this.subheading = subheadingArray[0] + capitalizeProductFamilyApiArray.join(' ') + ',' + subheadingArray[1];
    this.details = this.globals.CONTACT_SALES_PRODUCT_DETAILS;

    this.loaderService.loadingContent.emit(false);
  }
}
