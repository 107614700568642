import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FragmentService } from '../../services/fragment.service';
// import { isScullyRunning } from '@scullyio/ng-lib';
declare let APIMaticDevPortal: any;

@Component({
  selector: 'app-merchant-boarding',
  templateUrl: './merchant-boarding.component.html',
  styleUrls: ['./merchant-boarding.component.scss'],
})
export class MerchantBoardingComponent implements OnInit, AfterViewInit {
  tryItButtons;
  authLabelText = 'If you want to generate a key and try out the API, please create a free account and login.';
  previousRouteSusbription = 0;
  hasContent = true;
  @ViewChild('parent') parent: ElementRef;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private elementRef: ElementRef,
    private _renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const meta = {
      template: 'sdk',
      'secondary-nav': [{ title: 'API Specification', path: '/merchant-boarding' }],
    };
    this.metaService.metadata.next(meta);
    // this.renderer2.appendChild(document.head, scriptCore);
    this.loaderService.loadingContent.emit(false);
  }

  ngAfterViewInit(): void {
    const script = this._renderer.createElement('script');
    // script.src = 'https://dxjs.apimatic.io/v7/static/js/portal.v7.js';
    script.src = 'assets/matic/merchant-boarding/static/js/portal.v7-uber.js';
    
    script.defer = true;
    script.onload = () => {
      const showScript = this._renderer.createElement('script');
      showScript.src = 'assets/matic/merchant-boarding/static/js/portal.js';
      this._renderer.appendChild(this.parent.nativeElement, showScript);
      // APIMaticDevPortal.show({
      //   container: 'apimatic-widget',
      //   portalStyle: 'default',
      //   apiKey: 'xxx',
      //   baseUrl: './',
      //   enableExport: true,
      //   enableAnalytics: true,
      //   enableConsoleCalls: true,
      //   useProxyForConsoleCalls: true,
      //   languageSettings: {
      //     cs_net_standard_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     java_eclipse_jre_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     php_generic_lib_v2: { sdkDownloadLink: '', disableSdkDownload: false },
      //     python_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     ruby_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     ts_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     go_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //   },
      //   allowedExportFormats: [
      //     'openapi31json',
      //     'openapi31yaml',
      //     'openapi3json',
      //     'openapi3yaml',
      //     'swagger20',
      //     'swaggeryaml',
      //     'swagger10',
      //     'raml',
      //     'raml10',
      //     'apiblueprint',
      //     'insomnia',
      //     'insomniayaml',
      //     'wadl2009',
      //     'wsdl',
      //     'apimatic',
      //     'postman10',
      //     'postman20',
      //   ],
      //   initialPlatform: 'cs_net_standard_lib',
      //   themeOverrides: {
      //     palette: { 
      //       primaryColor: '#0c7ff2',
      //       linkColor: '#00C7D4',
      //       colors: {
      //         C600: 'green'
      //       }
      //      },
      //     fontSource: [],
      //     cssStyles: {
      //       headings: {
      //         fontFamily: '',
      //         h1: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '27px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h2: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '24px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h3: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '21.36px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h4: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '18px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h5: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '16px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h6: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '15px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //       },
      //       body: {
      //         fontFamily: "Source Sans Pro",
      //         text1: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '15px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //         text2: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '13.33px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //         text3: {
      //           fontFamily: "Source Sans Pro",
      //           fontSize: '11.85px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //       },
      //       code: {
      //         fontFamily: 'Courier Prime, monospace',
      //         inlineCode: {
      //           fontFamily: 'Courier Prime, monospace',
      //           fontSize: '15px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //         blockCode: {
      //           fontFamily: 'Courier Prime, monospace',
      //           fontSize: '15px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //       },
      //     },
      //   },
      //   codegenApiRoutes: {
      //     docsgen: 'assets/matic/merchant-boarding/static/docs/launchpad-merchant-boarding-pos-{template}.json',
      //     codegen: 'assets/matic/merchant-boarding/static/sdks/launchpad-merchant-boarding-pos-{template}.zip',
      //     transform: 'assets/matic/merchant-boarding/static/exports/launchpad-merchant-boarding-pos-{format}.{ext}',
      //   },
      //   renameHttpToRest: false,
      // });
    };
    // this._renderer.setProperty(script, 'text', this.hmScript);
    this._renderer.appendChild(this.parent.nativeElement, script);
  }
}
