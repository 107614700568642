import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import { Component, OnChanges, Input } from '@angular/core';
import { FragmentService } from '../../services/fragment.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
// import { isScullyRunning } from '@scullyio/ng-lib';

@Component({
  selector: 'app-spec-hoster',
  templateUrl: './spec-hoster.component.html',
  styleUrls: ['./spec-hoster.component.scss'],
})
export class SpecHosterComponent extends BaseComponent implements OnChanges {
  @Input() isApiMaticSpec = false;
  apiMaticPFamily;
  apiMaticProduct;
  apiMaticProductVersion;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private router: Router
  ) {
    super(fragmentService, loaderService, metaService);
  }

  ngOnChanges(change) {
    this.loaderService.loadingContent.emit(true);
    if (this.isApiMaticSpec) {
      const urlPartsFragments = this.router.url.split('#');
      const urlParts = urlPartsFragments[0].split('/');
      this.apiMaticPFamily = urlParts[2];
      this.apiMaticProduct = urlParts[3];
      this.apiMaticProductVersion = urlParts[5] || 'current';
    }
  }
}
