import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../services/page.service';
import { MetaService } from '../../services/meta.service';
import { Globals } from '../../globals';
import { fade } from '../../animations/fade';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { FirstPageLoadService } from 'app/services/firstSiteLoad.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfigService } from 'app/services/config.service';

// eslint-disable-next-line @typescript-eslint/ban-types
// declare let gtag: Function;

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  animations: [fade],
})
export class PageComponent implements OnInit, OnDestroy, AfterViewInit {
  // Env
  env = environment;
  isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  meta = {};
  categories = {};
  body = '';
  hasContent = true;
  loadingContent = true;
  transitionState = 'complete';
  pageRoute = '';
  staticPage = '';
  pageTypes = [];
  firstPageLoad = true;
  isSecNav = false;
  isDocIndex = false;
  navMenuItems = [];
  pageSubscription: Subscription;
  getapikeyInfoModal = false;
  sidebarShow: boolean;
  routeRedocSubscription = 0;
  isApiMaticSpec = false;
  routeUrl = '';
  apiFourOFour = false;
  contactSales = false;
  isSpecPage = false;

  constructor(
    public globals: Globals,
    public store: Store<any>,
    public loaderService: LoaderService,
    private pageService: PageService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private firstPageLoadService: FirstPageLoadService,
    private router: Router,
    private configService: ConfigService
  ) {}

  ngOnDestroy() {
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (this.router.url) {
      const urlParam = this.router.url.split('#id_token');
      if (urlParam.length >= 2) {
        window.location.href = '/';
      }
    }
  }

  private isSpecOnVersion() {
    const urlPartsFragments = this.router.url.split('/');
    return urlPartsFragments.length > 5;
  }

  ngOnInit() {
    this.contactSales = false;
    this.route.url.subscribe(() => {
      let intervalRunCount = 0;
      const pendoTimeInterval = setInterval(() => {
        intervalRunCount++;
        const pendoStyleElement = document.querySelectorAll('style[type="text/css"]');
        if (pendoStyleElement.length > 0) {
          clearInterval(pendoTimeInterval);
          pendoStyleElement.forEach((element) => {
            const elementClass = element.getAttribute('class');
            if (elementClass?.includes('pendo-style')) {
              element.remove();
            }
          });
        } else {
          if (intervalRunCount > 9) {
            clearInterval(pendoTimeInterval);
          }
        }
      }, 1000);

      if (this.router.url.includes('/api-specification')) {
        this.isSpecPage = true;
        if (this.isSpecOnVersion()) {
          this.isApiMaticSpec = false;
          this.routeUrl = `/${this.route.snapshot.url.map((o) => o.path).join('/')}`.split('?').shift();
          this.getPageInfo(this.routeUrl);
        } else {
          this.configService.getConfig('apimatic-products').subscribe(
            (apiMaticProductResponse) => {
              if (apiMaticProductResponse.Items && apiMaticProductResponse.Items.length > 0) {
                const apiMaticProducts = apiMaticProductResponse.Items[0].apimatic;
                apiMaticProducts.forEach((item) => {
                  if (this.router.url.includes(item.name + '/api-specification')) {
                    this.isApiMaticSpec = true;
                    const secondaryNav = [
                      {
                        title: 'Documentation',
                        path: item.family ? '/apis/' + item.family + '/' + item.name : '/apis/' + item.name,
                      },
                      {
                        title: 'API Specification',
                        path: item.family
                          ? '/apis/' + item.family + '/' + item.name + '/api-specification'
                          : '/apis/' + item.name,
                      },
                    ];
                    const meta = {
                      versions: item.versions,
                      template: 'redoc',
                      title: item.name,
                      'secondary-nav': secondaryNav,
                    };
                    this.update({ metadata: meta, categories: null, body: null, title: null });
                    // this.router.navigateByUrl(`/api-specification/${item.family}/${item.name}`);
                    return;
                  }
                });
              }
              if (!this.isApiMaticSpec) {
                this.router.navigateByUrl('/404', { skipLocationChange: true });
              }
            },
            (error) => {
              this.router.navigateByUrl('/404', { skipLocationChange: true });
            }
          );
        }
      } else {
        this.isSpecPage = false;
        this.isApiMaticSpec = false;
      }

      // Scully integration work
      // Ignore loading service when navigating between anchor tags
      // if (this.pageRoute !== this.route.snapshot.url.map((o) => o.path).join('/')) {
      //   console.log('page component');
      //   this.loaderService.loadingContent.emit(this.firstPageLoadService.loadContent());
      // }
      this.loadingContent = false;
      const currentUrl = window.location.href;
      //check for contact sales specific to product
      if (currentUrl.split('/').indexOf('contact-sales') != -1 && this.route.snapshot.url.length - 1 != 0) {
        this.contactSales = true;
        this.routeUrl = '/' + this.route.snapshot.url.join('/').replace('/contact-sales', '');
        this.getPageInfo(this.routeUrl);
      } else {
        this.contactSales = false;
      }
      if (!this.contactSales && !this.isSpecPage) {
        this.routeUrl = `/${this.route.snapshot.url.map((o) => o.path).join('/')}`.split('?').shift();
        this.getPageInfo(this.routeUrl);
      }
    });
    this.metaService.metadata.pipe(distinctUntilChanged()).subscribe((pMeta) => {
      this.isSecNav = pMeta && pMeta['sec-nav'] === undefined ? true : pMeta['sec-nav'];
      this.isDocIndex = pMeta && pMeta['page-index'] === undefined ? false : pMeta['page-index'];
    });
    this.pageService.getApiKeyConfirmEvent.subscribe(() => {
      this.getapikeyInfoModal = true;
    });
  }

  sidebarShowHandler(sidebarShow: boolean) {
    this.sidebarShow = sidebarShow;
  }

  getPageInfo(routeUrl: string) {
    this.pageRoute = this.route.snapshot.url.map((o) => o.path).join('/');
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
    }

    this.pageSubscription = this.pageService.getPage(routeUrl, this.route.snapshot.queryParams.token).subscribe(
      (page) => {
        if (!page.Item) {
          this.router.navigateByUrl('/404', { skipLocationChange: true });
        } else {
          this.update(page.Item);
          window.scrollTo({
            top: 0,
            behavior: 'auto',
          });
        }
      },
      (error) => {
        error.status === '403'
          ? this.router.navigateByUrl('/403', { skipLocationChange: true })
          : this.router.navigateByUrl('/404', { skipLocationChange: true });
      }
    );
  }
  update(pageItem: { metadata: any; categories: any; body: string; title: string }) {
    this.sidebarShow = false;
    this.meta = pageItem.metadata || {};
    if (this.meta['template'] === 'redoc') {
      this.routeRedocSubscription++;
    }
    this.metaService.metadata.next(this.meta);
    this.categories = pageItem.categories || {};
    this.body = pageItem.body || '';
    this.hasContent = true;
    this.metaService.setTitle([pageItem.title]);

    // if ('metadata' in pageItem) {
    //   this.metaService.setAllMeta('seo' in pageItem.metadata ? pageItem.metadata.seo : []);
    // }
  }

  // Add googleAnalytics() to (page) and (error) above
  // googleAnalytics() {
  //   if (environment.googleAnalytics !== '') {
  //     gtag('config', environment.googleAnalytics, {
  //       'page_path': window.location.pathname
  //     });
  //   }
  // }
  closeInfoModal(): void {
    this.getapikeyInfoModal = false;
  }
}
