import { environment } from '../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
import { AfterViewInit, Component, IterableDiffers, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormService } from 'app/services/form.service';
import { MetaService } from 'app/services/meta.service';
import { Globals } from '../../globals';
import { emailDomainValidator } from 'app/directives/email-domain-validator';
import { AccountService } from 'app/services/account.service';
import { LocalStorageService } from 'app/services/localStorage.service';
import { CognitoService } from 'app/services/cognito.service';
import { ApiService } from 'app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lead-gen',
  templateUrl: './lead-gen.component.html',
  styleUrls: ['./lead-gen.component.scss'],
})
export class LeadGenComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmation') confirmation: any;
  form: UntypedFormGroup;
  questions: any;
  showFailure = false;
  returnRoute = location.host + '/contact-confirmation';
  formURL: string;
  oid: string;
  campaignID: string;
  recordType: string;
  countryList: any[];
  currentCustomer: any[];
  customerQuries: any[];
  cities: any[];
  emailSantizeError = false;
  reference_Information_c: string;
  emailValue: string;
  customerOrProductQuries: any[];
  contactSalesSpecificToProduct = false;
  resizewidth = true;
  productLaunchpadEmail: any[];
  showSuccess = false;
  leadGenProcessing = false;
  recipients: string;

  constructor(
    private router: Router,
    public store: Store<any>,
    protected formService: FormService,
    private metaService: MetaService,
    public loaderService: LoaderService,
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
    private cognitoService: CognitoService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.loaderService.loadingContent.emit(true);
    window.scrollTo({ top: 0 });
    this.oid = environment.sfOrgId;
    this.campaignID = environment.sfCampaignID;
    this.recordType = environment.sfRecordType;
    this.accountService.pendoVisitorId.subscribe((vid) => {
      this.reference_Information_c = vid;
    });
  }

  ngAfterViewInit(): void {
    //code changes to get recipients from url
    const url = this.router.url.replace(/^\/|\/$/g, '');
    const urlPartsFragments = url.split('#');
    const urlParts = urlPartsFragments[0].split('/');
    this.recipients = urlParts[1];
    this.emailValue = this.cognitoService.getUserInfo() ? this.localStorageService.getItem('userEmail') : '';
    this.formService.getForm('lead-gen').subscribe((form) => {
      if (form.Items && form.Items.length > 0) {
        if (form.Items[0].questions) {
          this.countryList = form.Items[0].countries;
          this.questions = form.Items[0].questions;
          this.customerQuries = form.Items[0].customerQuries;
          //checking contact sales specific to product or not
          if (this.router.url.includes('contact-sales') && this.router.url.includes('apis')) {
            this.contactSalesSpecificToProduct = true;
            this.customerOrProductQuries = form.Items[0].productQuries[0].launchpad;
          } else {
            this.contactSalesSpecificToProduct = false;
            this.customerOrProductQuries = form.Items[0].customerQuries;
            this.formURL = environment.sfLeadSubmissionURL;
          }
          this.currentCustomer = form.Items[0].currentCustomer;
          this.form = this.toFormGroup(this.questions);
          // this.form.controls.country_code.setValue("");
          // this.form.controls.state_code.setValue("");

          this.loaderService.loadingContent.emit(false);
        }
      }
    });
  }

  toFormGroup(questions) {
    const group: any = {};
    questions.forEach((question) => {
      if (question.key === 'email') {
        group[question.key] = new UntypedFormControl(
          this.emailValue || '',
          Validators.compose([
            Validators.required,
            Validators.pattern(Globals.emailValidationRegEx),
            emailDomainValidator(),
          ])
        );
      } else if (question.key === 'url') {
        group[question.key] = new UntypedFormControl(
          '',
          Validators.compose([Validators.required, Validators.pattern('^.+\\..+$')])
        );
      } else if (question.key === 'phone') {
        group[question.key] = new UntypedFormControl('', Validators.compose([Validators.pattern(/^\+?[\d]{6,16}$/)]));
      } else if (question.key === 'Description_Hidden__c') {
        group[question.key] = new UntypedFormControl('', Validators.compose([Validators.pattern(/^[a-zA-Z0-9., ]+$/)]));
      } else {
        group[question.key] = question.required
          ? new UntypedFormControl(question.value || '', [
              Validators.required,
              this.formService.noWhitespaceValidator,
              this.formService.noInvalidNameValidator,
            ])
          : new UntypedFormControl(question.value || '');
      }
    });

    group['country_code'] = new UntypedFormControl('', Validators.required);
    group['state_code'] = new UntypedFormControl('', Validators.required);
    group['Config_Field1__c'] = new UntypedFormControl('', Validators.required);
    group['Product_Services_Interest__c'] = new UntypedFormControl('', Validators.required);

    group['phone'] = new UntypedFormControl('', Validators.compose([Validators.pattern(/^\+?[\d]{6,16}$/)]));

    return new UntypedFormGroup(group);
  }

  // // show all field errors on submit
  touchForm() {
    this.formService.markFormGroupTouched(this.form);
  }

  // private markFormGroupTouched(formGroup: FormGroup) {
  //   (<any>Object).values(formGroup.controls).forEach(control => {
  //     control.markAsTouched();

  //     if (control.controls) {
  //       this.markFormGroupTouched(control);
  //     }
  //   });
  // }

  // swap out state/province/region list based on country
  private changeCountry(count) {
    if (this.countryList.find((con) => con.value == count)) {
      this.cities = this.countryList.find((con) => con.value == count).cities;
    } else {
      this.cities = [];
    }
    this.form.controls.state_code.setValue('');
    this.form.controls['country_code'].setValue(count);
  }

  private changeState(state) {
    this.form.controls['state_code'].setValue(state);
  }

  private changeCurrentCustomer(customer) {
    this.form.controls['Config_Field1__c'].setValue(customer);
  }

  private changeCustomerQuries(query) {
    if (this.contactSalesSpecificToProduct) {
      // check to salesforce
      if (query === 'Sales Inquiry' || query === 'Other') {
        this.contactSalesSpecificToProduct = false;
        this.formURL = environment.sfLeadSubmissionURL;
      } else {
        this.contactSalesSpecificToProduct = true;
        this.formURL = '';
      }
    }
    this.form.controls['Product_Services_Interest__c'].setValue(query);
  }

  private changePhoneNumber(query) {
    this.form.controls['phone'].setValue(query);
  }

  sendMail() {
    this.showFailure = false;
    this.showSuccess = false;
    this.formService.markFormGroupTouched(this.form);
    const body =
      'Worldpay Developer Portal\t\n\t\n' +
      'Name: ' +
      this.form.value.first_name +
      this.form.value.last_name +
      '\t\n' +
      'Email: ' +
      this.form.value.email +
      '\t\n' +
      'Phone: ' +
      this.form.value.phone +
      '\t\n' +
      'Current Customer: ' +
      this.form.value.Config_Field1__c +
      '\t\n' +
      'Position: ' +
      this.form.value.title +
      '\t\n' +
      'Company Name: ' +
      this.form.value.company +
      '\t\n' +
      'Company Website: ' +
      this.form.value.url +
      '\t\n' +
      'Company Locale: ' +
      this.form.value.country_code +
      '\t\n' +
      'Company State/Region: ' +
      this.form.value.state_code +
      '\t\n\t\n' +
      'Topic: ' +
      this.form.value.Product_Services_Interest__c +
      '\t\n' +
      '\t\nThanks,\t\n' +
      'Worldpay Developer Poratl Support Team.';

    const emailData = {
      recipients: this.recipients,
      subject: 'Product Inquiry',
      body: body,
    };

    if (this.form.valid) {
      this.leadGenProcessing = true;
      this.apiService.post('/api/email', emailData).subscribe((resp) => {
        if (resp && (resp.status = 500)) {
          this.showFailure = true;
        } else {
          this.showSuccess = true;
        }
        this.leadGenProcessing = false;
        window.scrollTo({
          top: 0,
          behavior: 'auto',
        });
      });
    }
    return false;
  }

  clickEmpty() {
    return false;
  }
  // public noWhitespaceValidator(control: FormControl) {
  //   const isWhitespace = (control.value || '').trim().length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { 'whitespace': true };
  // }
}
