import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FragmentService } from '../../services/fragment.service';
// import { isScullyRunning } from '@scullyio/ng-lib';
declare let APIMaticDevPortal: any;
declare let wkScript: any;

@Component({
  selector: 'app-auth-research',
  templateUrl: './auth-research.component.html',
  styleUrls: ['./auth-research.component.scss'],
})
export class AuthResearchComponent implements OnInit, AfterViewInit {
  tryItButtons;
  authLabelText = 'If you want to generate a key and try out the API, please create a free account and login.';
  previousRouteSusbription = 0;
  hasContent = true;
  @ViewChild('parent') parent: ElementRef;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private elementRef: ElementRef,
    private _renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const meta = {
      versions: ['1.0.0'],
      template: 'sdk',
      'secondary-nav': [
        {
          title: 'Documentation',
          path: '/auth-research/',
        },
        { title: 'API Specification', path: '/auth-research/api-specification' },
      ],
    };
    this.metaService.metadata.next(meta);
    // this.renderer2.appendChild(document.head, scriptCore);
    this.loaderService.loadingContent.emit(false);
  }

  ngAfterViewInit(): void {
    const script = this._renderer.createElement('script');
    script.src = `assets/matic/auth-research/static/js/portal.v7-uber.js`;
    script.defer = true;
    script.onload = () => {
      const showScript = this._renderer.createElement('script');
      showScript.src = 'assets/matic/auth-research/static/js/portal.js';
      this._renderer.appendChild(this.parent.nativeElement, showScript);
      showScript.onload = () => {
        const walkThroughScript = this._renderer.createElement('script');
        walkThroughScript.src = `assets/matic/auth-research/static/walkthroughs/walkthrough.js`;
        script.defer = true;
        this._renderer.appendChild(this.parent.nativeElement, walkThroughScript);
      };

      // APIMaticDevPortal.show({
      //   container: 'apimatic-widget',
      //   portalStyle: 'default',
      //   apiKey: 'xxx',
      //   baseUrl: './',
      //   enableExport: true,
      //   enableAnalytics: true,
      //   enableConsoleCalls: true,
      //   useProxyForConsoleCalls: true,
      //   languageSettings: {
      //     cs_net_standard_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     java_eclipse_jre_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     php_generic_lib_v2: { sdkDownloadLink: '', disableSdkDownload: false },
      //     python_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     ruby_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     ts_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //     go_generic_lib: { sdkDownloadLink: '', disableSdkDownload: false },
      //   },
      //   allowedExportFormats: [
      //     'openapi31json',
      //     'openapi31yaml',
      //     'openapi3json',
      //     'openapi3yaml',
      //     'swagger20',
      //     'swaggeryaml',
      //     'swagger10',
      //     'raml',
      //     'raml10',
      //     'apiblueprint',
      //     'insomnia',
      //     'insomniayaml',
      //     'wadl2009',
      //     'wsdl',
      //     'apimatic',
      //     'postman10',
      //     'postman20',
      //   ],
      //   initialPlatform: 'cs_net_standard_lib',
      //   themeOverrides: {
      //     palette: { primaryColor: '#0c7ff2', linkColor: '#00C7D4' },
      //     fontSource: [
      //       "https://fonts.googleapis.com/css?family='Rubik':100,100i,300,300i,400,400i,500,500i,700,700i,900,900i",
      //       'https://fonts.googleapis.com/css?family=Courier+Prime:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i',
      //     ],
      //     cssStyles: {
      //       headings: {
      //         fontFamily: '',
      //         h1: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '27px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h2: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '24px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h3: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '21.36px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h4: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '18px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h5: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '16px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //         h6: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '15px',
      //           fontWeight: '500',
      //           fontStyle: 'normal',
      //           lineHeight: '1.3',
      //         },
      //       },
      //       body: {
      //         fontFamily: "'Rubik', sans-serif",
      //         text1: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '15px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //         text2: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '13.33px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //         text3: {
      //           fontFamily: "'Rubik', sans-serif",
      //           fontSize: '11.85px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //       },
      //       code: {
      //         fontFamily: 'Courier Prime, monospace',
      //         inlineCode: {
      //           fontFamily: 'Courier Prime, monospace',
      //           fontSize: '15px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //         blockCode: {
      //           fontFamily: 'Courier Prime, monospace',
      //           fontSize: '15px',
      //           fontWeight: '400',
      //           fontStyle: 'normal',
      //           lineHeight: '1.75',
      //         },
      //       },
      //     },
      //   },
      //   codegenApiRoutes: {
      //     docsgen: 'assets/matic/auth-research/static/docs/reporting-authorization-research-api-{template}.json',
      //     codegen: 'assets/matic/auth-research/static/sdks/reporting-authorization-research-api-{template}.zip',
      //     transform: 'assets/matic/auth-research/static/exports/reporting-authorization-research-api-{format}.{ext}',
      //   },
      //   renameHttpToRest: false,
      // });
    };
    // this._renderer.setProperty(script, 'text', this.hmScript);
    this._renderer.appendChild(this.parent.nativeElement, script);
  }
}
