<!-- <div class="wpdh-api-versions">
  <li *ngFor="let version of versions">
    {{version}}
  </li>
</div> -->

<div class="version-parent" (clickOutside)="clickedOutside()">
  <div class="dropdown">
    <button class="version-button" (click)="showVersions = !showVersions">
      Version
      <button-icon 
        icon="dropdown" 
        iconcolor="-colorSupportingWhite"
        iconattributes="-sm" 
        class="-icon-padding">
      </button-icon>
    </button>
    <div class="dropdown-wrapper" *ngIf="showVersions">
      <div class="dropdown-content" *ngFor="let version of versions">
        <a [routerLink]="version.route">
          {{version.name}}
        </a>
      </div>
    </div>
  </div>
</div>
