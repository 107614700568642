import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import { BreakpointService } from '../../modules/breakpoint/breakpoint.service';
import {
  Component,
  ElementRef,
  AfterContentInit,
  ViewEncapsulation,
  OnDestroy,
  Renderer2,
  OnChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FragmentService } from '../../services/fragment.service';
import { BaseComponent } from '../base/base.component';
import * as lzutf8 from 'lzutf8';

import { Subscription } from 'rxjs';
import { TokenService } from 'app/services/token.service';
import { CognitoService } from 'app/services/cognito.service';
import { Router } from '@angular/router';
// import { isScullyRunning } from '@scullyio/ng-lib';
// eslint-disable-next-line no-var
declare var redocSandbox: any;

@Component({
  selector: 'app-redoc',
  templateUrl: './redoc.component.html',
  styleUrls: ['./redoc.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RedocComponent extends BaseComponent implements AfterContentInit, OnDestroy, OnChanges {
  public redocSample = null;
  private redocState = null;
  metadata = null;
  subscription = new Subscription();
  launchFamilyArr = ['LaunchpadDrive'];
  isFraudSight = false;
  isTripos = false;
  authFieldCheckCounter;
  tryItButtons;
  authLabelText = 'If you want to generate a key and try out the API, please create a free account and login.';
  previousRouteSusbription = 0;
  constructor(
    private sanitizer: DomSanitizer,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private breakpointService: BreakpointService,
    private tokenService: TokenService,
    private cognitoService: CognitoService,
    private router: Router
  ) {
    super(fragmentService, loaderService, metaService);
  }

  disableFraudSightFields() {
    const tryoutButtonGetter = () => {
      if (document.querySelectorAll('.sc-EJAja > button').length === 0) {
        setTimeout(() => {
          tryoutButtonGetter();
        }, 300);
      } else {
        const tryItButtons = document.querySelectorAll('.sc-EJAja > button');
        tryItButtons.forEach((button) => {
          const formGetter = () => {
            if (button.parentElement.querySelector('.try-it-out-form') === null) {
              setTimeout(() => {
                formGetter();
              }, 300);
            } else {
              const tryItForm = button.parentElement.querySelector('.try-it-out-form');
              const formInputs = tryItForm.querySelectorAll('input');
              formInputs.forEach((input) => {
                if (input.getAttribute('name') !== 'apiKey') {
                  return;
                }
                input.setAttribute('readonly', 'true');
              });
            }
          };
          formGetter();
        });
        this.loaderService.loadingContent.emit(false);
      }
    };
    tryoutButtonGetter();
  }

  makeAuthReadOnly() {
    this.authFieldCheckCounter = 0;
    const readonlyAuthInput = () => {
      setTimeout(() => {
        if (document.querySelectorAll('.form-input-auth').length === 0) {
          if (this.authFieldCheckCounter <= 5) {
            this.authFieldCheckCounter++;
            readonlyAuthInput();
          } else {
            this.loaderService.loadingContent.emit(false);
          }
        } else {
          const inputs = document.querySelectorAll('.form-input-auth');
          const authLabel = document.querySelector('.auth-description');
          if (inputs.length) {
            const keyField = <HTMLInputElement>inputs[0];
            if (!this.cognitoService.getUserInfo()) {
              authLabel.textContent = this.authLabelText;
              keyField.setAttribute('hidden', 'true');
              this.loaderService.loadingContent.emit(false);
            } else {
              keyField.removeAttribute('hidden');
            }
          }
          this.loaderService.loadingContent.emit(false);
        }
      }, 300);
    };
    if (
      (this.metaService.metadata.value.sandbox === undefined || this.meta.sandbox) &&
      !this.isFraudSight &&
      !this.isTripos
    ) {
      // this.loaderService.loadingContent.emit(true);
      readonlyAuthInput();
    } else {
      this.loaderService.loadingContent.emit(false);
    }
  }

  setAuthToken() {
    if (
      this.cognitoService.getUserInfo() &&
      (this.metaService.metadata.value.sandbox === undefined || this.meta.sandbox) &&
      (this.metaService.metadata.value['hide-api-key'] === undefined ||
        !this.metaService.metadata.value['hide-api-key'])
    ) {
      this.displayToken();
    }
  }

  ngAfterViewInit(): void {
    this.previousRouteSusbription = this.routeRedocSubscription;
    this.metadata = this.metaService.metadata.value;
    const menuContainer: HTMLElement = this.elementRef.nativeElement.querySelector('.menu-content');
    if (menuContainer) {
      menuContainer.style.cssText = this.metadata['secondary-nav'] ? 'top: 140px' : 'top: 70px';
    }
    const sectionContainer = this.elementRef.nativeElement.querySelectorAll('.kzDJPW');
    this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
      if (['lg', 'md', 'sm'].indexOf(breakpoint) === -1) {
        sectionContainer.forEach((item, currentActiveIndex) => {
          item.style.cssText = 'margin-top: 220px !important';
        });
      } else {
        sectionContainer.forEach((item, currentActiveIndex) => {
          item.style.cssText = 'margin-top: 140px !important';
        });
      }
    });
  }

  ngOnChanges(change) {
    this.isFraudSight = window.location.href.includes('fraudsight/api-specification');
    this.isTripos =
      window.location.href.includes('tripos-lane/api-specification') ||
      window.location.href.includes('tripos-transaction/api-specification');

    if (this.previousRouteSusbription !== this.routeRedocSubscription) {
      if (this.isFraudSight && !this.cognitoService.getUserInfo()) {
        this.disableFraudSightFields();
      }
      this.loaderService.loadingContent.emit(true);
      this.makeAuthReadOnly();
      this.setAuthToken();
    }

    this.metadata = this.metaService.metadata.value;
    if (change.body) {
      if (!change.body.firstChange) {
        // Remove listeners and scripts to rebuild
        redocSandbox.removeSendButtonListeners();
        if (document.getElementById('redoc-state')) {
          document.getElementById('redoc-state').remove();
        }

        if (document.getElementById('ace-script')) {
          document.getElementById('ace-script').remove();
        }

        // Run build function
        this.attachDocumentationComponent();
      }
    }
  }

  ngOnDestroy() {
    redocSandbox.removeSendButtonListeners();
    if (document.getElementById('redoc-state')) {
      document.getElementById('redoc-state').remove();
    }
    if (document.getElementById('redoc-state')) {
      document.getElementById('ace-script').remove();
    }
    this.elementRef.nativeElement.remove();
    this.subscription.unsubscribe();
  }

  ngAfterContentInit() {
    this.attachDocumentationComponent();
  }

  async displayToken() {
    let launchpadPricing;
    let keyField: any;
    const keyResetter = () => {
      if (!keyField) return;
      keyField.placeholder = 'API Key';
      keyField.removeAttribute('readonly');
    };

    const inputInterval = setInterval(() => {
      if (this.isFraudSight) {
        clearInterval(inputInterval);
        return;
      }
      const inputs = document.querySelectorAll('.form-input-auth');
      if (!inputs.length) return;
      clearInterval(inputInterval);
      keyField = inputs[0];
      keyField.placeholder = 'Fetching API Key...';
      keyField.setAttribute('readonly', 'true');
    }, 1000);

    if (this.isFraudSight) {
      const tryoutButtonGetter = () => {
        if (document.querySelectorAll('.sc-EJAja > button').length === 0) {
          setTimeout(() => {
            tryoutButtonGetter();
          }, 300);
        } else {
          const tryItButtons = document.querySelectorAll('.sc-EJAja > button');
          tryItButtons.forEach((button) => {
            const formGetter = () => {
              if (button.parentElement.querySelector('.try-it-out-form') === null) {
                setTimeout(() => {
                  formGetter();
                }, 300);
              } else {
                const tryItForm = button.parentElement.querySelector('.try-it-out-form');
                const formInputs = tryItForm.querySelectorAll('input');
                formInputs.forEach((input) => {
                  if (input.getAttribute('name') !== 'apiKey') {
                    return;
                  }
                  input.value = 'Fetching API Key...';
                  input.setAttribute('readonly', 'true');
                });
              }
            };
            formGetter();
          });
        }
      };
      tryoutButtonGetter();
      const sendButtons = document.querySelectorAll('#send-button');
      sendButtons.forEach((button: HTMLElement) => {
        button.style.cssText = 'pointer-events: none';
      });
    }

    this.subscription.add(
      this.tokenService.getAllTokens().subscribe(
        async (response) => {
          const data = response.data || [];
          if (data.length <= 0) {
            keyResetter();
          } else {
            let selectedApiKey;
            const filteredData = data.filter((item: any) => {
              let appNameTrim = item.appName;
              appNameTrim = appNameTrim.replace(/\s/g, '');
              if (appNameTrim === 'LaunchpadPricing') {
                launchpadPricing = item;
              }
              return appNameTrim === this.metadata.api;
            });

            filteredData.sort((a: any, b: any) => b?.acceptorId - a?.acceptorId);
            selectedApiKey =
              (filteredData[0]?.accessToken ? `Bearer ${filteredData[0]?.accessToken}` : '') ||
              filteredData[0]?.accountToken ||
              filteredData[0]?.consumerKey ||
              (filteredData[0]?.license && filteredData[0]?.license !== '-'
                ? `WORLDPAY license='${filteredData[0]?.license}'`
                : '') ||
              '';

            if (this.launchFamilyArr.includes(this.metadata.api) && launchpadPricing?.license) {
              selectedApiKey = `WORLDPAY license='${launchpadPricing?.license}'`;
            }

            if (
              document.getElementsByName('global-auth').length !== 0 &&
              response.data &&
              response.data[0] &&
              selectedApiKey
            ) {
              keyField.value = selectedApiKey;
              keyResetter();
            } else {
              keyResetter();
            }

            if (this.isFraudSight) {
              const tryoutButtonGetter = () => {
                if (document.querySelectorAll('.sc-EJAja > button').length === 0) {
                  setTimeout(() => {
                    tryoutButtonGetter();
                  }, 300);
                } else {
                  const tryItButtons = document.querySelectorAll('.sc-EJAja > button');
                  tryItButtons.forEach((button) => {
                    const tryItForm = button.parentElement.querySelector('.try-it-out-form');
                    const formInputs = tryItForm.querySelectorAll('input');
                    formInputs.forEach((input) => {
                      if (input.getAttribute('name') !== 'apiKey') {
                        return;
                      }
                      const fraudSight = data.find((da) => {
                        return da.appName === 'FraudSight';
                      });
                      input.removeAttribute('readonly');
                      input.value = fraudSight ? (fraudSight.license ? fraudSight.license : '') : '';
                    });
                    button.addEventListener('click', () => {
                      const tryItForm = button.parentElement.querySelector('.try-it-out-form');
                      const formInputs = tryItForm.querySelectorAll('input');
                      formInputs.forEach((input) => {
                        if (input.getAttribute('name') !== 'apiKey') {
                          return;
                        }
                        const fraudSight = data.find((da) => {
                          return da.appName === 'FraudSight';
                        });

                        input.value = fraudSight ? (fraudSight.license ? fraudSight.license : '') : '';
                      });
                    });
                  });
                }
              };
              tryoutButtonGetter();
              const sendButtons = document.querySelectorAll('#send-button');
              sendButtons.forEach((button: HTMLElement) => {
                button.style.cssText = 'pointer-events: all';
              });
            }
          }
        },
        () => {
          keyResetter();
          if (this.isFraudSight) {
            this.tryItButtons.forEach((button) => {
              const tryItForm = button.parentElement.querySelector('.try-it-out-form');
              const formInputs = tryItForm.querySelectorAll('input');
              formInputs.forEach((input) => {
                if (input.getAttribute('name') !== 'apiKey') {
                  return;
                }
                input.value = '';
              });
            });

            const sendButtons = document.querySelectorAll('#send-button');
            sendButtons.forEach((button: HTMLElement) => {
              button.style.cssText = 'pointer-events: all';
            });
          }
        }
      )
    );
  }

  async attachDocumentationComponent(): Promise<any> {
    this.body = lzutf8.decompress(new Uint8Array(this.body.data));
    if (this.body) {
      this.redocState = this.body
        .match(/<script>(.|\n)*?<\/script>/gi)[0]
        .replace(/<script>/, '')
        .replace(/<\/script>/, '')
        .replace(/\r?\n|\r/g, '')
        .replace(/&lt;/g, '')
        .replace(/&quot/, '"')
        .replace('const __redoc_state', '__redoc_state');
      this.redocState = String(this.redocState);
      this.redocSample = this.sanitizer.bypassSecurityTrustHtml(this.body.replace(/<script>(.|\n)*?<\/script>/gi, ''));

      // if (!isScullyRunning()) {
      this.addRedocScripts();
      // }
    }
  }

  async addRedocScripts() {
    const scriptAce = this.renderer2.createElement('script');
    scriptAce.id = 'ace-script';
    scriptAce.src = '../../assets/ace/ace-editor.min.js?v=3';
    scriptAce.defer = true;
    this.renderer2.appendChild(document.head, scriptAce);

    if (document.getElementById('redoc-script') === null) {
      const scriptCore = this.renderer2.createElement('script');
      scriptCore.onload = this.addStateScript.bind(this);
      scriptCore.id = 'redoc-script';
      scriptCore.src = '../../assets/redoc/redoc.js?v=3';
      scriptCore.defer = true;
      this.renderer2.appendChild(document.head, scriptCore);
    } else {
      scriptAce.onload = this.addStateScript.bind(this);
    }
  }

  addStateScript() {
    const redocState = this.renderer2.createElement('script');

    if (this.metaService.metadata.value.license) {
      redocState.onload = this.addMetadataAuth();
    }

    if (this.meta?.specDownload) {
      document.querySelector('.api-info p a').setAttribute('href', this.meta?.specDownload);
    }

    redocState.defer = true;
    redocState.id = 'redoc-state';
    redocState.text = this.redocState;
    redocState.type = 'text/javascript';
    // redocState.nonce = '2fjaFnwRRcas47s';
    redocState.onload = this.addSandbox();
    this.renderer2.appendChild(document.head, redocState);
  }

  addSandbox() {
    if (document.getElementById('redoc-state') === null) {
      setTimeout(() => {
        this.addSandbox();
      }, 300);
    } else {
      if (this.metaService.metadata.value.sandbox === undefined || this.meta.sandbox) {
        redocSandbox.createSandbox();
        this.updateSendButton();
        this.openTryOuts();
      }
    }
  }

  updateSendButton() {
    if (document.querySelectorAll('#send-button').length <= 0) {
      setTimeout(() => {
        this.updateSendButton();
      }, 300);
    } else {
      if (!this.cognitoService.getUserInfo()) {
        const sendButtons = document.querySelectorAll('#send-button');
        sendButtons.forEach((button: HTMLElement) => {
          button.setAttribute('id', 'send-new-button');
          button.style.cssText = 'padding: 5px; margin: 0.5rem auto; cursor:pointer; text-align:center';
          button.innerText = 'Login to try it';
          button.addEventListener('click', (event) => {
            this.router.navigate(['/account/login']);
          });
        });
      }
    }
  }

  openTryOuts() {
    if (document.querySelectorAll('.sc-EJAja > button').length <= 0) {
      if (document.querySelectorAll('.sc-duzrYq > button').length <= 0) {
        setTimeout(() => {
          this.openTryOuts();
        }, 300);
      } else {
        const tryOutButtons = document.querySelectorAll('.sc-duzrYq > button');
        tryOutButtons.forEach((button: HTMLButtonElement) => {
          button.click();
        });
      }
    } else {
      const tryOutButtons = document.querySelectorAll('.sc-EJAja > button');
      tryOutButtons.forEach((button: HTMLButtonElement) => {
        button.click();
      });
    }
  }

  addMetadataAuth() {
    if (document.getElementsByName('global-auth').length === 0) {
      setTimeout(() => {
        this.addMetadataAuth();
      }, 50);
    } else {
      (<HTMLInputElement>document.getElementsByName('global-auth')[0]).value = this.meta.license;
    }
  }
}
