// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// Old AWS Instance dev mode
// export const environment = {
//   production: false,
//   envName: 'dev',
//   apiHost: 'http://wpdev.local:8888',
//   apiKey: '27M5HfgkUD5BSgeZ7EQrR5WRYFbYUbud9oSJ57Qv',
//   sandboxApiHost: 'https://fl9y2g4mm1.execute-api.us-east-1.amazonaws.com/test',
//   sandboxApiKey: 'JEl50N9HUD4NQrOj0uJWSzX6PmmrQTI7XeuzaZJ7',
//   instanaKey: '',
//   UserPoolId: 'us-east-2_X5pHdFbOK',
//   ClientId: '1jvndl861838n9i6rcjel5dfua',
//   RedirectUriSignIn : 'https://wp-devhub-dev-catalyst.s3.us-east-2.amazonaws.com/index.html',
//   RedirectUriSignOut : 'https://wp-devhub-dev-catalyst.s3.us-east-2.amazonaws.com/index.html',
//   TokenScopesArray: ['email', 'phone', 'openid', 'aws.cognito.signin.user.admin', 'profile' ],
//   AppWebDomain : 'wp-test-auth.auth.us-east-2.amazoncognito.com',
// };

export const environment = {
  production: false,
  envName: 'development',
  // front app old URL
  oldAppURL: 'https://dev-developer.fisglobal.com',
  // front app API configurations
  apiHost: 'https://dev-docs.worldpay.com', // API host url
  selfcertApiHost: "https://dev-eapi.fisglobal.com",
  apiKey: 'vmdNN2iIawafp5619E8tJ4PNrSCeY4bMaKlZHIjG', // API key for request authentication
  // Cognito app details for user signup and signin
  UserPoolId: 'us-east-2_8ZGttwqaO', // Cognito user pool id
  // NO HTTPS, ONLY BASE URL
  AppWebDomain: 'dev-developer-fisglobal.auth.us-east-2.amazoncognito.com', // Cognito app domain url
  ClientId: 'b9sqps58k6s6o3ebmu1mdtaus', // Cognito app request client id key
  RedirectUriSignIn: 'https://dev-docs.worldpay.com/index.html', // Cognito app configuration for redirect url after signin
  RedirectUriSignOut: 'https://dev-docs.worldpay.com/account/logout/', // Cognito app configuration for redirect url after sign out
  TokenScopesArray: ['email', 'phone', 'openid', 'aws.cognito.signin.user.admin', 'profile'], // Cognito app configuration for scopes
  googleAnalytics: 'G-R6EW9XR9RQ',
  googleAnalyticsUrl: 'https://www.googletagmanager.com/gtag/js?id=',
  content: '../../../content/content',
  // pendo app details (to collect user behaviour)
  pendoApiKey: 'bb88e7bb-ad27-40d7-4d24-0c4d8c28dacb',
  pendoAccountId: 'DevEngine',
  // Whoson Chatbot configurations
  chatEnabled: true, // enables and disables chats in the portal
  chatUrl: '//worldpayusgw.whoson.com/include.js?domain=developerengine.fisglobal.com', //Whoson chat url for integration
  chatTokenInterval: 190000,
  // Salesforce web to lead form configurations
  sfLeadSubmissionURL: 'https://payments--nauat.sandbox.my.salesforce-sites.com/servlet/servlet.WebToLead?encoding=UTF-8', // salesforce web to lead form submission url
  sfOrgId: '00DVB000002Kc4n', // salesforce web to lead configuration for organization Id
  sfCampaignID: '7013h000000ULBBAA4', // salesforce web to lead configuration for campaign Id
  sfRecordType: '0123h000000MnxaAAC',
  // marketo configuration
  marketoCodeId: '975-BCU-707',
  marketoCodeSrcUrl: '//munchkin.marketo.net/munchkin.js',
  googleTagManager: 'GTM-PV7XVCP',
  // One Trust Configuration
  functionalCookiedId: 'optanon-category-C0003',
  marketingCookieId: 'optanon-category-C0004',
  performanceCookiedId: 'optanon-category-C0002'
};
