<ng-container *ngIf="!isApiMaticSpec">
  <app-redoc
    [body]="body"
    [categories]="categories"
    [pageRoute]="pageRoute"
    [routeRedocSubscription]="routeRedocSubscription">
  </app-redoc>
</ng-container>

<ng-container *ngIf="isApiMaticSpec">
  <div style="height: calc(100vh - 143px);">
      <app-api-spec
        [apiFamily]="apiMaticPFamily"
        [apiName]="apiMaticProduct" 
        [apiVersion]="apiMaticProductVersion">
      </app-api-spec>
  </div>
</ng-container>